import React from 'react'
import Header from './Components/Header'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Footer from './Components/Footer'
import Home from './Pages/Home'
import About from './Pages/About'
import OurServices from './Pages/OurServices'
import Partners from './Pages/Partners'
import Buildings from './Pages/Buildings'
import Contact from './Pages/Contact'

function App() {
  return (
    <Router>
      <Header />
      <div className="container-fluid p-0">
        <Route path="/" component={Home} exact />
        <Route path="/sobre" component={About} exact />
        <Route path="/servicos" component={OurServices} exact />
        <Route path="/parceiros" component={Partners} exact />
        <Route path="/obras" component={Buildings} exact />
        <Route path="/contato" component={Contact} exact />
      </div>
      <Footer />
    </Router>
  )
}

export default App
