import React from 'react'
import styles from './styles.module.css'
import { FaWhatsappSquare } from 'react-icons/fa'

export default function(){
    return (
        <>
            <footer className={"text-center " + styles.footer}>
                Rua Monsenhor José Paulino, 628 - Centro | Congonhal, MG | 37584-000 | (35) 3424-1188
            </footer>
            <FaWhatsappSquare onClick={() => window.open("https://api.whatsapp.com/send?phone=5535999873559&text=&source=&data=", "_blank")} className={styles.whatsIco + " d-none d-sm-block"} />
        </>
    )
}