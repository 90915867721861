import React from 'react'
import styles from './styles.module.css'
import banner from '../../assets/img/banners/CONTATO.png'

export default function(){
    return (
        <>
            <div className={styles.banner}>
                <img src={banner} className="img-fluid" alt="Algumas de nossas obras" />
            </div>
            <main>
                <div className="row justify-content-center">
                    <div className="col-10 col-sm-5">
                        <form className="form">
                            <div className="form-group mp-form-group">
                                <label className="mp-label">Nome</label>
                                <input required type="text" className="form-control"/>
                            </div>
                            <div className="form-group mp-form-group">
                                <label className="mp-label">E-mail</label>
                                <input required type="text" className="form-control"/>
                            </div>
                            <div className="form-group mp-form-group">
                                <label className="mp-label">Empresa</label>
                                <input required type="text" className="form-control"/>
                            </div>
                            <div className="form-group mp-form-group">
                                <label className="mp-label">Mensagem</label>
                                <textarea required className="form-control" rows="5"></textarea>
                            </div>
                            <div className="form-group text-right">
                                <button className="btn btn-success rounded-pill">Enviar</button>
                            </div>
                        </form>
                    </div>
                    <div className="col-10 col-sm-5">
                        <div className={styles.contatoComercial}>
                            <h4 className="text-center">Departamento Comercial</h4>
                            <p><strong>Silvio Almeida</strong><br />(35) 99987-3559</p>
                            <p><strong>Isaias Bezerra Neto</strong><br />(35) 99941-1188</p>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}