import React, { useState } from 'react'

import styles from './styles.module.css'

import banner from '../../assets/img/banners/NOSSOSSERVICOS.png'
import foto2 from '../../assets/img/servicos/servico_final.jpg'
import foto3 from '../../assets/img/servicos/concretagem.jpg'
import ModalImage from '../../Components/ModalImage'

export default function(){
    const [visibleFoto2, setVisibleFoto2] = useState(false)
    const [visibleFoto3, setVisibleFoto3] = useState(false)
    return (
        <>
            <ModalImage image={foto2} visible={visibleFoto2} onClose={() => setVisibleFoto2(false)} />
            <ModalImage image={foto3} visible={visibleFoto3} onClose={() => setVisibleFoto3(false)} />
            <div className={styles.banner}>
                <img src={banner} className="img-fluid" alt="Nossos Serviços" />
            </div>
            <main className="row justify-content-center">
                <div className="col-10">
                    <div className="row justify-content-md-center">
                        <div className="col-xs-12 col-sm-6">
                            <div className={"card " + styles.noBorder}>
                                <div className={"embed-responsive embed-responsive-16by9 " + styles.embed}>
                                    <iframe title="Vídeo" className={"embed-responsive-item " + styles.containerService} src={`https://www.youtube.com/embed/iibX1K8Sz_Q`} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"></iframe>
                                </div>
                                <div className="card-body">
                                    <span className="card-text card-text-mp">Preparação das malhas para concretagem</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <div className={"card " + styles.noBorder}>
                                <img onClick={() => setVisibleFoto3(true)} src={foto3} alt="Imagem de um servico" className={"img-fluid " + styles.containerService} />
                                <div className="card-body">
                                    <span className="card-text card-text-mp">Concretagem</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <div className={"card " + styles.noBorder}>
                                <div className={"embed-responsive embed-responsive-16by9 " + styles.embed}>
                                    <iframe title="Vídeo" className={"embed-responsive-item " + styles.containerService} src={`https://www.youtube.com/embed/HNWy8B_nUEg`} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"></iframe>
                                </div>
                                <div className="card-body">
                                    <span className="card-text card-text-mp">Acabamento do piso</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <div className={"card " + styles.noBorder}>
                                <img onClick={() => setVisibleFoto2(true)} src={foto2} alt="Imagem de um servico" className={"img-fluid " + styles.containerService} />
                                <div className="card-body">
                                    <span className="card-text card-text-mp">Serviço final</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}