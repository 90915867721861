import React from 'react'

import styles from './styles.module.css'

export default function({ image, visible, onClose }){
    if(visible){
        return (
            <>
                <div className={styles.container} onClick={onClose}>
                    <img className={"img-fluid " + styles.img} src={image} alt="Imagem" />
                </div>
            </>
        )
    }else{
        return (
            <></>
        )
    }
}