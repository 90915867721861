import React from 'react'
import styles from './styles.module.css'
import img from '../../assets/img/sobre.jpg'
import about from '../../assets/img/banners/SOBREAEMPRESA.png'

export default function(){
    return (
        <>
            <div className={styles.banner}>
                {/* <TitleBanner>Sobre a empresa</TitleBanner> */}
                <img className="img-fluid" src={about} alt="MINASPISO" />
            </div>
            <main>
                <div className="row justify-content-md-center">
                    <div className="col-xs-12 col-sm-3">
                        <img className={ "img-fluid " + styles.imageAbout } src={img} alt="MINASPISO" />
                    </div>
                    <div className="col-xs-12 col-sm-7 m-3 text-justify">
                        <h2 className={styles.subtitle}>Um encontro casual de dois ex-funcionários (um Piauiense e um Mineiro) de uma empresa polidora de pisos e voltados para um mesmo objetivo, resolvem abrir uma empresa no segmento de que eles mais entendem PISO POLIDO E DE ALTA RESISTÊNCIA, então nasce a MINASPISO.</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-10 m-auto pt-5">
                        <h3 className="font-weight-bold">Visão</h3>

                        <ul>
                            <li>Compromisso</li>
                            <li>Trabalho em equipe</li>
                            <li>Foco no resultado</li>
                            <li>Inovação</li>
                        </ul>

                        <h3 className="font-weight-bold pt-3">Missão</h3>
                        <p>
                        Atender as necessidades dos nossos clientes se especializando em desenvolvimento e tecnologia em pavimentação de concreto, trabalhando sempre com responsabilidade ambiental.
                        </p>
                    </div>
                </div>
            </main>
        </>
    )
}