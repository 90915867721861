import React, { useState, useCallback } from 'react'

import styles from './styles.module.css'
import banner from '../../assets/img/banners/OBRAS.png'
import ImageViewer from 'react-simple-image-viewer'

const buildings = [
    {
        src: "wL_gex54YhY",
        type: "video"
    },
    {
        src: require("../../assets/img/obras/imagem1.jpeg"),
        type: 'image'
    },
    {
        src: require("../../assets/img/obras/imagem2.jpeg"),
        type: 'image'
    },
    {
        src: require("../../assets/img/obras/imagem3.jpeg"),
        type: 'image'
    },
    {
        src: require("../../assets/img/obras/imagem4.jpeg"),
        type: 'image'
    },
    {
        src: require("../../assets/img/obras/imagem5.jpeg"),
        type: 'image'
    },
    {
        src: require("../../assets/img/obras/imagem6.jpeg"),
        type: 'image'
    },
    {
        src: require("../../assets/img/obras/imagem7.jpeg"),
        type: 'image'
    },
    {
        src: require("../../assets/img/obras/imagem8.jpeg"),
        type: 'image'
    },
    {
        src: require("../../assets/img/obras/imagem9.jpeg"),
        type: 'image'
    },
    {
        src: require("../../assets/img/obras/imagem10.jpeg"),
        type: 'image'
    },
    {
        src: require("../../assets/img/obras/imagem11.jpeg"),
        type: 'image'
    },
    {
        src: require("../../assets/img/obras/imagem12.jpeg"),
        type: 'image'
    },
    {
        src: require("../../assets/img/obras/imagem13.jpeg"),
        type: 'image'
    },
    {
        src: require("../../assets/img/obras/imagem14.jpeg"),
        type: 'image'
    },
    {
        src: require("../../assets/img/obras/imagem15.jpeg"),
        type: 'image'
    },
    {
        src: require("../../assets/img/obras/imagem16.jpeg"),
        type: 'image'
    },
    {
        src: require("../../assets/img/obras/imagem17.jpeg"),
        type: 'image'
    },
    {
        src: require("../../assets/img/obras/imagem18.jpeg"),
        type: 'image'
    },
    {
        src: require("../../assets/img/obras/imagem19.jpeg"),
        type: 'image'
    },
    {
        src: require("../../assets/img/obras/imagem20.jpeg"),
        type: 'image'
    },
    {
        src: require("../../assets/img/obras/imagem21.jpeg"),
        type: 'image'
    },
    {
        src: require("../../assets/img/obras/imagem22.jpeg"),
        type: 'image'
    },
    {
        src: require("../../assets/img/obras/imagem23.jpeg"),
        type: 'image'
    },
    {
        src: require("../../assets/img/obras/imagem24.jpeg"),
        type: 'image'
    },
    {
        src: require("../../assets/img/obras/imagem25.jpeg"),
        type: 'image'
    },
    {
        src: require("../../assets/img/obras/imagem26.jpeg"),
        type: 'image'
    },
    {
        src: require("../../assets/img/obras/imagem27.jpeg"),
        type: 'image'
    },
    {
        src: require("../../assets/img/obras/imagem28.jpeg"),
        type: 'image'
    },
    {
        src: require("../../assets/img/obras/imagem29.jpeg"),
        type: 'image'
    },
    {
        src: require("../../assets/img/obras/imagem30.jpeg"),
        type: 'image'
    },
    {
        src: require("../../assets/img/obras/imagem31.jpeg"),
        type: 'image'
    },
    {
        src: require("../../assets/img/obras/imagem32.jpeg"),
        type: 'image'
    },
    {
        src: require("../../assets/img/obras/imagem33.jpeg"),
        type: 'image'
    },
    {
        src: require("../../assets/img/obras/imagem34.jpeg"),
        type: 'image'
    },
    {
        src: require("../../assets/img/obras/imagem35.jpeg"),
        type: 'image'
    },
    {
        src: require("../../assets/img/obras/imagem36.jpeg"),
        type: 'image'
    },
    {
        src: require("../../assets/img/obras/imagem37.jpeg"),
        type: 'image'
    },
    {
        src: require("../../assets/img/obras/imagem38.jpeg"),
        type: 'image'
    },
    {
        src: require("../../assets/img/obras/imagem39.jpeg"),
        type: 'image'
    },
    {
        src: require("../../assets/img/obras/imagem40.jpeg"),
        type: 'image'
    },
    {
        src: require("../../assets/img/obras/imagem41.jpeg"),
        type: 'image'
    },
    {
        src: require("../../assets/img/obras/imagem42.jpeg"),
        type: 'image'
    },
    {
        src: "j3nz_LyHXFo",
        type: "video"
    },
    {
        src: "Pfko8yzul7M",
        type: "video"
    },
    {
        src: "-q3XGSQHV3w",
        type: "video"
    },
    {
        src: "G3-ECy3HGfs",
        type: "video"
    },
    {
        src: "GBwjl1GCMNQ",
        type: "video"
    },
    {
        src: "uo1nKfGD-1k",
        type: "video"
    },
    {
        src: "r_0NY3ohXVQ",
        type: "video"
    }
]

export default function(){
    const [openViewerImages, setOpenViewerImages] = useState(false)
    const [currentImage, setCurrentImage] = useState(0)
    const images = buildings
                        .filter(build => build.type === 'image')
                        .map(build => build.src)

    const openImageViewer = useCallback((index) => {
        setCurrentImage(index)
        setOpenViewerImages(true)
    }, [])
    
    const closeImageViewer = () => {
        setCurrentImage(0)
        setOpenViewerImages(false)
    }

    return (
        <>
            <div className={styles.banner}>
                <img src={banner} className="img-fluid" alt="Algumas de nossas obras" />
            </div>
            <main>
                <div className="row justify-content-md-center">
                    <div className="col-xs-12 col-sm-10">
                        <div className="row">
                            {
                                buildings.map((building, index) => (
                                    <div className={"col-6 col-sm-3 " + styles.buildingContainer}>
                                        {
                                            building.type === 'image' ? (
                                                
                                                <img onClick={() => openImageViewer(index)} className="img-fluid" src={building.src} alt="Building" key={index} />
                                            ) : (
                                                <div className="embed-responsive embed-responsive-16by9" key={index}>
                                                    <iframe className="embed-responsive-item" title="video" src={`https://www.youtube.com/embed/${building.src}`} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"></iframe>
                                                </div>
                                            )
                                        } 
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </main>
            {
                openViewerImages && (
                    <ImageViewer
                        src={ images }
                        currentIndex={ currentImage }
                        onClose={ closeImageViewer }
                    />
                )
            }
        </>
    )
}