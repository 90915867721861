import React from 'react'

import styles from './styles.module.css'
import banner from '../../assets/img/banners/PARCEIROS.png'

const partners = [
    {
        src: require("../../assets/img/parceiros/parceiro(1).png")
    },
    {
        src: require("../../assets/img/parceiros/parceiro(2).png")
    },
    {
        src: require("../../assets/img/parceiros/parceiro(3).png")
    },
    {
        src: require("../../assets/img/parceiros/parceiro(4).png")
    },
    {
        src: require("../../assets/img/parceiros/parceiro(5).png")
    },
    {
        src: require("../../assets/img/parceiros/parceiro(6).png")
    },
    {
        src: require("../../assets/img/parceiros/parceiro(7).png")
    },
    {
        src: require("../../assets/img/parceiros/parceiro(8).png")
    },
    {
        src: require("../../assets/img/parceiros/parceiro(9).png")
    },
    {
        src: require("../../assets/img/parceiros/parceiro(10).png")
    },
    {
        src: require("../../assets/img/parceiros/parceiro(11).png")
    },
    {
        src: require("../../assets/img/parceiros/parceiro(12).png")
    }
]

export default function(){
    return (
        <>
            <div className={styles.banner}>
                <img src={banner} className="img-fluid" alt="Nossos Serviços" />
            </div>
            <main>
                <div className="row justify-content-md-center">
                    <div className="col-xs-12 col-sm-10">
                        <div className="row">
                            {
                                partners.map(partner => (
                                    <div className="col-6 col-sm-3">
                                        <img src={partner.src} className="img-fluid" alt="Parceiro" />
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}